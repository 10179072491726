<template>
  <b-col cols="12" v-if="page_settings.isLoading">
    <div class="text-center center" style="top: 0; right: 50">
      <span class="loader"></span>
      <h4>Loading, please wait...</h4>
      <p>This might take some time to load...</p>
    </div>
  </b-col>
  <b-col cols="12" v-else-if="page_settings.isError">
    <div class="text-center center">
      <img
        src="@/assets/img/pages/error/warning.svg"
        alt="Error"
        style="max-height: 130px"
      />
      <h2>Oh no :(</h2>
      <p>
        We encountered an error while trying to retrieve the Treasury data.
        <br />
        Please try again later.
        <br /><br />
        Error: {{ page_settings.errorMessage }}
      </p>
    </div>
  </b-col>
  <b-col v-else cols="12" class="mb-32">
    <b-row>
      <b-col cols="12" class="mb-32">
        <b-card-group deck>
          <b-card class="border-0">
            <b-row>
              <b-col cols="4" class="text-center">
                <b-avatar
                  src="https://cryptologos.cc/logos/solana-sol-logo.png"
                  size="50"
                  class="mb-2 mt-0"
                />
                <h4>SOL</h4>
              </b-col>
              <b-col cols="8" class="text-right">
                <h4>{{ top_widgets.sol.amount.toFixed(2) }}</h4>
                <h3>
                  ${{
                    (top_widgets.sol.amount * marketdata.sol.current).toFixed(2)
                  }}
                </h3>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="border-0">
            <b-row>
              <b-col cols="4" class="text-center">
                <b-avatar
                  src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png"
                  size="50"
                  class="mb-2 mt-0"
                />
                <h4>USDC</h4>
              </b-col>
              <b-col cols="8" class="text-right">
                <h4>{{ top_widgets.usdc.amount.toFixed(2) }}</h4>
                <h3>
                  ${{
                    (top_widgets.usdc.amount * marketdata.usdc.current).toFixed(
                      2
                    )
                  }}
                </h3>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="border-0">
            <b-row>
              <b-col cols="4" class="text-center">
                <b-avatar
                  src="https://assets.coingecko.com/coins/images/20360/small/gene-token.png"
                  size="50"
                  class="mb-2 mt-0"
                />
                <h4>GENE</h4>
              </b-col>
              <b-col cols="8" class="text-right">
                <h4>{{ top_widgets.gene.amount.toFixed(2) }}</h4>
                <h3>
                  ${{
                    (top_widgets.gene.amount * marketdata.gene.current).toFixed(
                      2
                    )
                  }}
                </h3>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="border-0">
            <b-row>
              <b-col cols="4" class="text-center">
                <b-avatar
                  src="https://assets.coingecko.com/coins/images/20360/small/gene-token.png"
                  size="50"
                  class="mb-2 mt-0"
                />
                <h4>sGene</h4>
              </b-col>
              <b-col cols="8" class="text-right">
                <h4>{{ top_widgets.sgene.amount.toFixed(2) }}</h4>
                <!--<h3>
                  ${{
                    (
                      top_widgets.sgene.amount * marketdata.sgene.current
                    ).toFixed(2)
                  }}
                </h3>-->
              </b-col>
            </b-row>
          </b-card>
          <b-card class="border-0">
            <b-row>
              <b-col cols="4" class="text-center">
                <b-avatar
                  src="https://assets.coingecko.com/coins/images/26135/small/genopets_ki.png"
                  size="50"
                  class="mb-2 mt-0"
                />
                <h4>Ki</h4>
              </b-col>
              <b-col cols="8" class="text-right">
                <h4>{{ top_widgets.ki.amount.toFixed(2) }}</h4>
                <h3>
                  ${{
                    (top_widgets.ki.amount * marketdata.ki.current).toFixed(2)
                  }}
                </h3>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col cols="12" lg="9">
        <b-card class="mb-16 border-0 shadow">
          <b-row>
            <b-col cols="12" lg="2" class="text-center center">
              <b-avatar
                src="https://cdn.genopets.me/habitats/4sbUsUz5JrdKwqoEWPuZTPYFaUFHnnyqp1jgKxJKy59B.png"
                size="128"
                class="mb-16"
                lazy
              />
              <h4 class="mb-0">Earth Wallet</h4>
              <p class="mb-0 text-black-80 hp-text-color-dark-30">
                183 Habitats
              </p>
              <h4 class="mb-0 mt-2">${{ walletsdata.earth.total_value }}</h4>
            </b-col>
            <b-col cols="12" lg="10">
              <b-row>
                <b-col cols="12" lg="5">
                  <b-row
                    v-for="(item, index) in walletsdata.earth.series"
                    :key="index"
                  >
                    <b-col
                      class="w-auto"
                      cols="6"
                      style="display: flex; flex-direction: row"
                    >
                      <b-avatar :src="item.logo" size="32px" lazy> </b-avatar>
                      <p
                        class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                      >
                        {{ item.name }}
                      </p>
                    </b-col>

                    <b-col class="pl-0" cols="6" align="end">
                      <h4 class="mt-1">
                        {{ Number(item.data).toFixed(2) }}
                      </h4>
                      <!--<p class="hp-text-color-dark-30">
                        &nbsp;/
                        {{
                          (
                            Number(item.data) *
                            marketdata[item.name.toLowerCase()].current
                          ).toFixed(2)
                        }}$
                      </p>-->
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="walletsdata.earth.seeds.length == 0"
                  cols="12"
                  lg="4"
                >
                  <h4 class="text-center">No seeds in the wallet</h4>
                </b-col>
                <b-col v-else cols="12" lg="4">
                  <b-row
                    v-for="(item, index) in walletsdata.earth.seeds"
                    :key="index"
                  >
                    <b-col
                      class="w-auto"
                      cols="8"
                      style="display: flex; flex-direction: row"
                    >
                      <b-avatar :src="item.logo" size="32px" lazy> </b-avatar>
                      <p
                        class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                      >
                        {{ item.name }}
                      </p>
                    </b-col>

                    <b-col class="pl-0" cols="4" align="end">
                      <h4 class="mt-1">{{ Number(item.data) }}</h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="3" class="text-center center">
                  <b-avatar
                    :src="globaltreasury.crystals.crystal_earth.logo"
                    size="128"
                    class="mb-16"
                    lazy
                  />
                  <h4 class="mb-0">Earth Crystal</h4>
                  <h4 class="mb-0 mt-2">
                    {{ globaltreasury.crystals.crystal_earth.amount }}
                  </h4>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="mb-16 border-0 shadow">
          <b-row>
            <b-col cols="12" lg="2" class="text-center center">
              <b-avatar
                src="https://cdn.genopets.me/habitats/9NkWQvJzNHX23FTqKwxKuVX4fye6ZC4kK1c7CdgpG7k.png"
                size="128"
                class="mb-16"
                lazy
              />
              <h4 class="mb-0">Fire Wallet</h4>
              <p class="mb-0 text-black-80 hp-text-color-dark-30">
                183 Habitats
              </p>
              <h4 class="mb-0 mt-2">${{ walletsdata.fire.total_value }}</h4>
            </b-col>
            <b-col cols="12" lg="10">
              <b-row>
                <b-col cols="12" lg="5">
                  <b-row
                    v-for="(item, index) in walletsdata.fire.series"
                    :key="index"
                  >
                    <b-col
                      class="w-auto"
                      cols="6"
                      style="display: flex; flex-direction: row"
                    >
                      <b-avatar :src="item.logo" size="32px" lazy> </b-avatar>
                      <p
                        class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                      >
                        {{ item.name }}
                      </p>
                    </b-col>

                    <b-col class="pl-0" cols="6" align="end">
                      <h4 class="mt-1">
                        {{ Number(item.data).toFixed(2) }}
                      </h4>
                      <!--<p class="hp-text-color-dark-30">
                        &nbsp;/
                        {{
                          (
                            Number(item.data) *
                            marketdata[item.name.toLowerCase()].current
                          ).toFixed(2)
                        }}$
                      </p>-->
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="walletsdata.fire.seeds.length == 0"
                  cols="12"
                  lg="4"
                >
                  <h4 class="text-center">No seeds in the wallet</h4>
                </b-col>
                <b-col v-else cols="12" lg="4">
                  <b-row
                    v-for="(item, index) in walletsdata.fire.seeds"
                    :key="index"
                  >
                    <b-col
                      class="w-auto"
                      cols="8"
                      style="display: flex; flex-direction: row"
                    >
                      <b-avatar :src="item.logo" size="32px" lazy> </b-avatar>
                      <p
                        class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                      >
                        {{ item.name }}
                      </p>
                    </b-col>

                    <b-col class="pl-0" cols="4" align="end">
                      <h4 class="mt-1">{{ Number(item.data) }}</h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="3" class="text-center center">
                  <b-avatar
                    :src="globaltreasury.crystals.crystal_fire.logo"
                    size="128"
                    class="mb-16"
                    lazy
                  />
                  <h4 class="mb-0">Fire Crystal</h4>
                  <h4 class="mb-0 mt-2">
                    {{ globaltreasury.crystals.crystal_fire.amount }}
                  </h4>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="mb-16 border-0 shadow">
          <b-row>
            <b-col cols="12" lg="2" class="text-center center">
              <b-avatar
                src="https://cdn.genopets.me/habitats/4PqVdWiqfn5nMhwfbb8J8jALorVpQ1qeLcS6i1i1cjQ4.png"
                size="128"
                class="mb-16"
                lazy
              />
              <h4 class="mb-0">Metal Wallet</h4>
              <p class="mb-0 text-black-80 hp-text-color-dark-30">
                184 Habitats
              </p>
              <h4 class="mb-0 mt-2">${{ walletsdata.metal.total_value }}</h4>
            </b-col>
            <b-col cols="12" lg="10">
              <b-row>
                <b-col cols="12" lg="5">
                  <b-row
                    v-for="(item, index) in walletsdata.metal.series"
                    :key="index"
                  >
                    <b-col
                      class="w-auto"
                      cols="6"
                      style="display: flex; flex-direction: row"
                    >
                      <b-avatar :src="item.logo" size="32px" lazy> </b-avatar>
                      <p
                        class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                      >
                        {{ item.name }}
                      </p>
                    </b-col>

                    <b-col class="pl-0" cols="6" align="end">
                      <h4 class="mt-1">
                        {{ Number(item.data).toFixed(2) }}
                      </h4>
                      <!--<p class="hp-text-color-dark-30">
                        &nbsp;/
                        {{
                          (
                            Number(item.data) *
                            marketdata[item.name.toLowerCase()].current
                          ).toFixed(2)
                        }}$
                      </p>-->
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="walletsdata.metal.seeds.length == 0"
                  cols="12"
                  lg="4"
                >
                  <h4 class="text-center">No seeds in the wallet</h4>
                </b-col>
                <b-col v-else cols="12" lg="4">
                  <b-row
                    v-for="(item, index) in walletsdata.metal.seeds"
                    :key="index"
                  >
                    <b-col
                      class="w-auto"
                      cols="8"
                      style="display: flex; flex-direction: row"
                    >
                      <b-avatar :src="item.logo" size="32px" lazy> </b-avatar>
                      <p
                        class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                      >
                        {{ item.name }}
                      </p>
                    </b-col>

                    <b-col class="pl-0" cols="4" align="end">
                      <h4 class="mt-1">{{ Number(item.data) }}</h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="3" class="text-center center">
                  <b-avatar
                    :src="globaltreasury.crystals.crystal_metal.logo"
                    size="128"
                    class="mb-16"
                    lazy
                  />
                  <h4 class="mb-0">Metal Crystal</h4>
                  <h4 class="mb-0 mt-2">
                    {{ globaltreasury.crystals.crystal_metal.amount }}
                  </h4>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="mb-16 border-0 shadow">
          <b-row>
            <b-col cols="12" lg="2" class="text-center center">
              <b-avatar
                src="https://cdn.genopets.me/habitats/3ASWYuTCe7YWtyzn9QvtPooeG5f6gkaqoTYFxoctUKhx.png"
                size="128"
                class="mb-16"
                lazy
              />
              <h4 class="mb-0">Water Wallet</h4>
              <p class="mb-0 text-black-80 hp-text-color-dark-30">
                183 Habitats
              </p>
              <h4 class="mb-0 mt-2">${{ walletsdata.water.total_value }}</h4>
            </b-col>
            <b-col cols="12" lg="10">
              <b-row>
                <b-col cols="12" lg="5">
                  <b-row
                    v-for="(item, index) in walletsdata.water.series"
                    :key="index"
                  >
                    <b-col
                      class="w-auto"
                      cols="6"
                      style="display: flex; flex-direction: row"
                    >
                      <b-avatar :src="item.logo" size="32px" lazy> </b-avatar>
                      <p
                        class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                      >
                        {{ item.name }}
                      </p>
                    </b-col>

                    <b-col class="pl-0" cols="6" align="end">
                      <h4 class="mt-1">
                        {{ Number(item.data).toFixed(2) }}
                      </h4>
                      <!--<p class="hp-text-color-dark-30">
                        &nbsp;/
                        {{
                          (
                            Number(item.data) *
                            marketdata[item.name.toLowerCase()].current
                          ).toFixed(2)
                        }}$
                      </p>-->
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="walletsdata.water.seeds.length == 0"
                  cols="12"
                  lg="4"
                >
                  <h4 class="text-center">No seeds in the wallet</h4>
                </b-col>
                <b-col v-else cols="12" lg="4">
                  <b-row
                    v-for="(item, index) in walletsdata.water.seeds"
                    :key="index"
                  >
                    <b-col
                      class="w-auto"
                      cols="8"
                      style="display: flex; flex-direction: row"
                    >
                      <b-avatar :src="item.logo" size="32px" lazy> </b-avatar>
                      <p
                        class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                      >
                        {{ item.name }}
                      </p>
                    </b-col>

                    <b-col class="pl-0" cols="4" align="end">
                      <h4 class="mt-1">{{ Number(item.data) }}</h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="3" class="text-center center">
                  <b-avatar
                    :src="globaltreasury.crystals.crystal_water.logo"
                    size="128"
                    class="mb-16"
                    lazy
                  />
                  <h4 class="mb-0">Water Crystal</h4>
                  <h4 class="mb-0 mt-2">
                    {{ globaltreasury.crystals.crystal_water.amount }}
                  </h4>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="mb-16 border-0 shadow">
          <b-row>
            <b-col cols="12" lg="2" class="text-center center">
              <b-avatar
                src="https://cdn.genopets.me/habitats/DGWMxNumaNoD3P98Mb2KM4kFJ5SSETNM6nURfqnjujcg.png"
                size="128"
                class="mb-16"
                lazy
              />
              <h4 class="mb-0">Wood Wallet</h4>
              <p class="mb-0 text-black-80 hp-text-color-dark-30">
                184 Habitats
              </p>
              <h4 class="mb-0 mt-2">${{ walletsdata.wood.total_value }}</h4>
            </b-col>
            <b-col cols="12" lg="10">
              <b-row>
                <b-col cols="12" lg="5">
                  <b-row
                    v-for="(item, index) in walletsdata.wood.series"
                    :key="index"
                  >
                    <b-col
                      class="w-auto"
                      cols="6"
                      style="display: flex; flex-direction: row"
                    >
                      <b-avatar :src="item.logo" size="32px" lazy> </b-avatar>
                      <p
                        class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                      >
                        {{ item.name }}
                      </p>
                    </b-col>

                    <b-col class="pl-0" cols="6" align="end">
                      <h4 class="mt-1">
                        {{ Number(item.data).toFixed(2) }}
                      </h4>
                      <!--<p class="hp-text-color-dark-30">
                        &nbsp;/
                        {{
                          (
                            Number(item.data) *
                            marketdata[item.name.toLowerCase()].current
                          ).toFixed(2)
                        }}$
                      </p>-->
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="walletsdata.wood.seeds.length == 0"
                  cols="12"
                  lg="4"
                >
                  <h4 class="text-center">No seeds in the wallet</h4>
                </b-col>
                <b-col v-else cols="12" lg="4">
                  <b-row
                    v-for="(item, index) in walletsdata.wood.seeds"
                    :key="index"
                  >
                    <b-col
                      class="w-auto"
                      cols="8"
                      style="display: flex; flex-direction: row"
                    >
                      <b-avatar :src="item.logo" size="32px" lazy> </b-avatar>
                      <p
                        class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                      >
                        {{ item.name }}
                      </p>
                    </b-col>

                    <b-col class="pl-0" cols="4" align="end">
                      <h4 class="mt-1">{{ Number(item.data) }}</h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="3" class="text-center center">
                  <b-avatar
                    :src="globaltreasury.crystals.crystal_wood.logo"
                    size="128"
                    class="mb-16"
                    lazy
                  />
                  <h4 class="mb-0">Wood Crystal</h4>
                  <h4 class="mb-0 mt-2">
                    {{ globaltreasury.crystals.crystal_wood.amount }}
                  </h4>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" lg="3">
        <b-card class="border-0 shadow">
          <b-row align-h="between">
            <b-col cols="6">
              <h5 class="mb-0">Global Treasury</h5>
            </b-col>

            <!--<b-col cols="6" class="hp-flex-none w-auto">
              <i
                class="ri-more-fill hp-text-color-dark-0 remix-icon"
                style="font-size: 24px"
              ></i>
            </b-col>-->
          </b-row>

          <div class="overflow-hidden mt-32">
            <apexchart
              type="donut"
              legend="global_treasury"
              height="400"
              :options="apexdata.globalTreasury.options"
              :series="apexdata.globalTreasury.series"
            ></apexchart>
          </div>
          <b-col>
            <b-button
              variant="outline-white"
              size="sm"
              class="btn-ghost btn-block"
              @click="
                globaltreasury.settings.showAll =
                  !globaltreasury.settings.showAll
              "
              ><b-icon
                class="mr-2"
                icon="chevron-down"
                v-if="!globaltreasury.settings.showAll"
              ></b-icon>
              <b-icon class="mr-2" icon="chevron-up" v-else></b-icon>
              <span v-if="!globaltreasury.settings.showAll" class="mr-2"
                >Show more</span
              >
              <span v-else class="mr-2">Show less</span
              ><b-icon
                class="mr-2"
                icon="chevron-down"
                v-if="!globaltreasury.settings.showAll"
              ></b-icon>
              <b-icon class="mr-2" icon="chevron-up" v-else></b-icon
            ></b-button>
          </b-col>
          <b-col
            v-if="globaltreasury.settings.showAll"
            cols="12"
            class="px-0 mt-24"
          >
            <b-row v-for="(item, index) in globaltreasury.market" :key="index">
              <b-col
                class="w-auto"
                cols="6"
                style="display: flex; flex-direction: row"
              >
                <b-avatar :src="item.logo" size="32px"> </b-avatar>
                <p
                  class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                >
                  {{ item.name }}
                </p>
              </b-col>

              <b-col class="pl-0" cols="6" align="end">
                <h4 class="mt-1">{{ item.amount.toFixed(2) }}</h4>
              </b-col>
            </b-row>
            <b-row v-for="(item, index) in globaltreasury.seeds" :key="index">
              <b-col
                class="w-auto"
                cols="6"
                style="display: flex; flex-direction: row"
              >
                <b-avatar :src="item.logo" size="32px"> </b-avatar>
                <p
                  class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                >
                  {{ item.name }}
                </p>
              </b-col>

              <b-col class="pl-0" cols="6" align="end">
                <h4 class="mt-1">{{ item.amount }}</h4>
              </b-col>
            </b-row>
            <b-row
              v-for="(item, index) in globaltreasury.crystals"
              :key="index"
            >
              <b-col
                class="w-auto"
                cols="6"
                style="display: flex; flex-direction: row"
              >
                <b-avatar :src="item.logo" size="32px"> </b-avatar>
                <p
                  class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                >
                  {{ item.name }}
                </p>
              </b-col>

              <b-col class="pl-0" cols="6" align="end">
                <h4 class="mt-1">{{ item.amount }}</h4>
              </b-col>
            </b-row>
          </b-col>
          <div class="divider"></div>
          <b-col cols="12" class="px-0 mt-24">
            <h5 class="mb-0">Market Price</h5>
            <p class="text-black-60 hp-text-color-dark-50">Live data</p>
            <b-row v-for="(item, index) in marketdata" :key="index">
              <b-col
                class="w-auto"
                cols="6"
                style="display: flex; flex-direction: row"
              >
                <b-avatar :src="item.logo" size="32px"> </b-avatar>
                <p
                  class="hp-p1-body mb-0 my-1 mx-8 text-black-80 hp-text-color-dark-30"
                >
                  {{ item.name }}
                </p>
              </b-col>

              <b-col class="pl-0" cols="6" align="end">
                <h4 class="mt-1">
                  {{ item.current }}$<span
                    class="hp-badge-text ml-8"
                    :class="item.change_color"
                    >{{ item.change }}</span
                  >
                </h4>
              </b-col>
            </b-row>
          </b-col>
          <div class="divider"></div>
          <b-col cols="12" class="px-0 mb-0">
            <h5 class="mb-2">Treasury Details</h5>
            <p class="">
              On 10/08/2022 Undisputed Noobs invested in the purchase of 917
              habitats at $136 each. For a total amount of $125,000.
              <br /><br />
              The data that are currently displayed are live data and updated in
              real time.
            </p>
          </b-col>
        </b-card>
      </b-col>
      <b-col cols="12" class="mt-32">
        <h4 class="text-center">Historical Data coming soon.</h4>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardGroup,
  BAvatar,
  BButton,
  BIcon,
} from "bootstrap-vue";
import genopetsAdminServices from "../../../services/genopets-admin.services";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardGroup,
    BAvatar,
    BButton,
    BIcon,
  },
  mounted() {
    genopetsAdminServices.getTreasury().then(
      (response) => {
        var wallets = response.wallets;
        var market = response.market;

        var addresses = {
          BemZ4ZkuQshUvHRMkrbVTTs5Fy83pjm1ff6etMU78b9p: "earth",
          Gf5kZnqZbTxaXEkPZzXnEpgKBsPR4Kgud8Cn34XBA16D: "fire",
          "7eQ4yrQBSUhW64A5VynBD6Bt2bGQiyATrBUGu3iamUq1": "metal",
          "4N38pyyez17XorKVaNWMVNaah9sfPmbayptGd2VSbi1r": "water",
          B58wmjqgoXJjmHGtCX6EEFcAbzeiqDGe54Q1aeK5gcdU: "wood",
        };

        for (var i in market) {
          var coin = this.marketdata[market[i].name];

          coin.current = market[i].data.current_price.usd;
          coin.full_current = market[i].data.current_price.usd;
          coin.current = coin.current.toFixed(4);
          coin.change = market[i].data.price_change_percentage_14d;

          if (coin.change > 0) {
            coin.change_color = "text-success";
          } else {
            coin.change_color = "text-danger";
          }

          coin.change = coin.change.toFixed(2) + "%";
        }

        for (var i in wallets) {
          var wallet = this.walletsdata[addresses[wallets[i].address]];

          for (var j in wallets[i].data) {
            if (wallets[i].data[j].name == "usdc") {
              this.top_widgets.usdc.amount =
                Number(this.top_widgets.usdc.amount) +
                Number(wallets[i].data[j].uiAmount);
              wallet.series.push({
                name: "USDC",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png",
              });

              this.walletsdata[addresses[wallets[i].address]].total_value +=
                wallets[i].data[j].uiAmount;

              this.globaltreasury.market.usdc.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "ki") {
              this.top_widgets.ki.amount =
                Number(this.top_widgets.ki.amount) +
                Number(wallets[i].data[j].uiAmount);
              wallet.series.push({
                name: "Ki",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://assets.coingecko.com/coins/images/26135/small/genopets_ki.png",
              });

              this.walletsdata[addresses[wallets[i].address]].total_value +=
                wallets[i].data[j].uiAmount * this.marketdata.ki.full_current;

              this.globaltreasury.market.ki.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "sol") {
              this.top_widgets.sol.amount =
                Number(this.top_widgets.sol.amount) +
                Number(wallets[i].data[j].uiAmount);
              wallet.series.push({
                name: "SOL",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://cryptologos.cc/logos/solana-sol-logo.png",
              });

              this.walletsdata[addresses[wallets[i].address]].total_value +=
                wallets[i].data[j].uiAmount * this.marketdata.sol.full_current;

              this.globaltreasury.market.sol.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "sGene") {
              this.top_widgets.sgene.amount =
                Number(this.top_widgets.sgene.amount) +
                Number(wallets[i].data[j].uiAmount);
              wallet.series.push({
                name: "sGene",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://assets.coingecko.com/coins/images/20360/small/gene-token.png",
              });

              this.globaltreasury.market.sgene.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "gene") {
              this.top_widgets.gene.amount =
                Number(this.top_widgets.gene.amount) +
                Number(wallets[i].data[j].uiAmount);
              wallet.series.push({
                name: "GENE",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://assets.coingecko.com/coins/images/20360/small/gene-token.png",
              });

              this.walletsdata[addresses[wallets[i].address]].total_value +=
                wallets[i].data[j].uiAmount * this.marketdata.gene.full_current;

              this.globaltreasury.market.gene.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "seed1") {
              wallet.seeds.push({
                name: "Grade 1 Seed",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://arweave.net/EdCi4OXfpHGBWA2lq_veo1HJq0GqJd-aD_W_cIUIwJI",
              });

              this.globaltreasury.seeds.seed1.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "seed2") {
              wallet.seeds.push({
                name: "Grade 2 Seed",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://arweave.net/fGnuVA9xnsi_kmVHpxfHTd1pRh9OZGAkhuh-7_KuaK8",
              });

              this.globaltreasury.seeds.seed2.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "seed3") {
              wallet.seeds.push({
                name: "Grade 3 Seed",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://arweave.net/TISXYsP9bNFGjHyO4LWXzb344zqdVLR07qmIeG2Cpb8",
              });

              this.globaltreasury.seeds.seed3.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "seed4") {
              wallet.seeds.push({
                name: "Grade 4 Seed",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://arweave.net/HFHMNdO-KDi0iqHPSINg0I_54nzaE9G-DLXxMtqqbUs",
              });

              this.globaltreasury.seeds.seed4.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "seed5") {
              wallet.seeds.push({
                name: "Grade 5 Seed",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://arweave.net/pV5AgoFcb5_cXvvRGwb9iMfS3VPh29KrBm2TJH4KaDg",
              });

              this.globaltreasury.seeds.seed5.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "seed6") {
              wallet.seeds.push({
                name: "Grade 6 Seed",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://arweave.net/OLrvFC2KB2Fu8I7RINjQABWXoSHGF5vg53-0G5BNaqs",
              });

              this.globaltreasury.seeds.seed6.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "crystal-earth") {
              wallet.crystals.push({
                name: "Refined Earth Crystal",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://arweave.net/kvcs7TChT5MioXXuUdjq9wjiEFSFASxOWSaJ6ff9gNQ?ext=gif",
              });

              this.globaltreasury.crystals.crystal_earth.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "crystal-fire") {
              wallet.crystals.push({
                name: "Refined Fire Crystal",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://arweave.net/Bpj5lBMu8KhL0Z7qCRgWJeah3R-c82pjBHpKVP1DJZs?ext=gif",
              });

              this.globaltreasury.crystals.crystal_fire.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "crystal-metal") {
              wallet.crystals.push({
                name: "Refined Metal Crystal",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://arweave.net/_9NU6rPAD9RARDrK_33V6XkAvuhDY-6MYjFm2tuuJJs?ext=gif",
              });

              this.globaltreasury.crystals.crystal_metal.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "crystal-water") {
              wallet.crystals.push({
                name: "Refined Water Crystal",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://arweave.net/KkPmQrTjsW60jedXQ5N0tJjBHLg3NRAuVgw06ZELDfg?ext=gif",
              });

              this.globaltreasury.crystals.crystal_water.amount +=
                wallets[i].data[j].uiAmount;
            } else if (wallets[i].data[j].name == "crystal-wood") {
              wallet.crystals.push({
                name: "Refined Wood Crystal",
                data: [wallets[i].data[j].uiAmount],
                logo: "https://arweave.net/4UCFrYJethPG8OIKlb8GZ7htav3jm3n65W-yIz6i2Zg?ext=gif",
              });

              this.globaltreasury.crystals.crystal_wood.amount +=
                wallets[i].data[j].uiAmount;
            }
          }
        }

        for (var i in this.walletsdata) {
          this.walletsdata[i].total_value =
            this.walletsdata[i].total_value.toFixed(3);

          this.apexdata.globalTreasury.series.push(
            Number(this.walletsdata[i].total_value)
          );

          this.apexdata.globalTreasury.options.labels.push(
            this.walletsdata[i].name
          );
        }

        this.page_settings.isLoading = false;
      },
      (error) => {
        console.log(error);

        this.page_settings.isLoading = false;
        this.page_settings.isError = true;
        this.page_settings.errorMessage = error.message;
      }
    );
  },
  data() {
    return {
      page_settings: {
        isLoading: true,
        isError: false,
        errorMessage: "",
      },
      top_widgets: {
        sol: {
          amount: 0,
          usd_amount: 0,
        },
        usdc: {
          amount: 0,
          usd_amount: 0,
        },
        gene: {
          amount: 0,
          usd_amount: 0,
        },
        sgene: {
          amount: 0,
          usd_amount: 0,
        },
        ki: {
          amount: 0,
          usd_amount: 0,
        },
      },
      globaltreasury: {
        settings: {
          showAll: false,
        },
        market: {
          sol: {
            amount: 0,
            logo: "https://cryptologos.cc/logos/solana-sol-logo.png",
            name: "SOL",
          },
          usdc: {
            amount: 0,
            logo: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png",
            name: "USD-Coin",
          },
          gene: {
            amount: 0,
            logo: "https://assets.coingecko.com/coins/images/20360/small/gene-token.png",
            name: "GENE",
          },
          sgene: {
            amount: 0,
            logo: "https://assets.coingecko.com/coins/images/20360/small/gene-token.png",
            name: "sGene",
          },
          ki: {
            amount: 0,
            logo: "https://assets.coingecko.com/coins/images/26135/small/genopets_ki.png",
            name: "Ki",
          },
        },
        seeds: {
          seed1: {
            amount: 0,
            logo: "https://arweave.net/EdCi4OXfpHGBWA2lq_veo1HJq0GqJd-aD_W_cIUIwJI",
            name: "Grade 1 Seed",
          },
          seed2: {
            amount: 0,
            logo: "https://arweave.net/fGnuVA9xnsi_kmVHpxfHTd1pRh9OZGAkhuh-7_KuaK8",
            name: "Grade 2 Seed",
          },
          seed3: {
            amount: 0,
            logo: "https://arweave.net/TISXYsP9bNFGjHyO4LWXzb344zqdVLR07qmIeG2Cpb8",
            name: "Grade 3 Seed",
          },
          seed4: {
            amount: 0,
            logo: "https://arweave.net/HFHMNdO-KDi0iqHPSINg0I_54nzaE9G-DLXxMtqqbUs",
            name: "Grade 4 Seed",
          },
          seed5: {
            amount: 0,
            logo: "https://arweave.net/pV5AgoFcb5_cXvvRGwb9iMfS3VPh29KrBm2TJH4KaDg",
            name: "Grade 5 Seed",
          },
          seed6: {
            amount: 0,
            logo: "https://arweave.net/OLrvFC2KB2Fu8I7RINjQABWXoSHGF5vg53-0G5BNaqs",
            name: "Grade 6 Seed",
          },
        },
        crystals: {
          crystal_earth: {
            amount: 0,
            logo: "https://arweave.net/kvcs7TChT5MioXXuUdjq9wjiEFSFASxOWSaJ6ff9gNQ?ext=gif",
            name: "Refined Earth Crystal",
          },
          crystal_fire: {
            amount: 0,
            logo: "https://arweave.net/Bpj5lBMu8KhL0Z7qCRgWJeah3R-c82pjBHpKVP1DJZs?ext=gif",
            name: "Refined Fire Crystal",
          },
          crystal_metal: {
            amount: 0,
            logo: "https://arweave.net/_9NU6rPAD9RARDrK_33V6XkAvuhDY-6MYjFm2tuuJJs?ext=gif",
            name: "Refined Metal Crystal",
          },
          crystal_water: {
            amount: 0,
            logo: "https://arweave.net/KkPmQrTjsW60jedXQ5N0tJjBHLg3NRAuVgw06ZELDfg?ext=gif",
            name: "Refined Water Crystal",
          },
          crystal_wood: {
            amount: 0,
            logo: "https://arweave.net/4UCFrYJethPG8OIKlb8GZ7htav3jm3n65W-yIz6i2Zg?ext=gif",
            name: "Refined Wood Crystal",
          },
        },
      },
      walletsdata: {
        earth: {
          name: "Earth",
          expanded: false,
          total_value: 0,
          series: [],
          seeds: [],
          crystals: [],
        },
        fire: {
          name: "Fire",
          expanded: false,
          total_value: 0,
          series: [],
          seeds: [],
          crystals: [],
        },
        metal: {
          name: "Metal",
          expanded: false,
          total_value: 0,
          series: [],
          seeds: [],
          crystals: [],
        },
        water: {
          name: "Water",
          expanded: false,
          total_value: 0,
          series: [],
          seeds: [],
          crystals: [],
        },
        wood: {
          name: "Wood",
          expanded: false,
          total_value: 0,
          series: [],
          seeds: [],
          crystals: [],
        },
      },
      marketdata: {
        sol: {
          full_current: 0,
          current: 0,
          change: 0,
          logo: "https://cryptologos.cc/logos/solana-sol-logo.png",
          name: "SOL",
          change_color: "text-success",
        },
        usdc: {
          full_current: 0,
          current: 0,
          change: 0,
          logo: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png",
          name: "USD-Coin",
          change_color: "text-success",
        },
        gene: {
          full_current: 0,
          current: 0,
          change: 0,
          logo: "https://assets.coingecko.com/coins/images/20360/small/gene-token.png",
          name: "GENE",
          change_color: "text-success",
        },
        sgene: {
          full_current: 0,
          current: "No data",
          change: null,
          logo: "https://assets.coingecko.com/coins/images/20360/small/gene-token.png",
          name: "sGene",
          change_color: "text-success",
        },
        ki: {
          full_current: 0,
          current: 0,
          change: 0,
          logo: "https://assets.coingecko.com/coins/images/26135/small/genopets_ki.png",
          name: "Ki",
          change_color: "text-success",
        },
      },
      apexdata: {
        globalTreasury: {
          series: [],
          options: {
            chart: {
              id: "global-trasury",
              fontFamily: "Manrope, sans-serif",
              type: "donut",
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            colors: ["#28a83c", "#eb5e34", "#686b68", "#24e5f7", "#7d5028"],

            labels: [],

            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              pie: {
                donut: {
                  size: "90%",
                  labels: {
                    show: true,
                    name: {
                      fontSize: "2rem",
                    },
                    value: {
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#2D3436",
                      formatter(val) {
                        return `$${val}`;
                      },
                    },
                    total: {
                      show: true,
                      fontSize: "24px",
                      fontWeight: "medium",
                      label: "Total",
                      color: "#636E72",

                      formatter: function (w) {
                        return `$${w.globals.seriesTotals.reduce((a, b) => {
                          return a + b;
                        }, 0)}`;
                      },
                    },
                  },
                },
              },
            },
            responsive: [
              {
                breakpoint: 426,
                options: {
                  legend: {
                    itemMargin: {
                      horizontal: 16,
                      vertical: 8,
                    },
                  },
                },
              },
            ],

            legend: {
              itemMargin: {
                horizontal: 6,
                vertical: 24,
              },
              horizontalAlign: "center",
              position: "bottom",
              fontSize: "12px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: 500,
              labels: {
                colors: "#2D3436",
              },

              markers: {
                radius: 12,
              },
            },
          },
        },
      },
      options: {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
          },
        },
        colors: ["#00F7BF", "#1BE7FF", "#0010F7"],
        fill: {
          type: "solid",
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "12px",
          labels: {
            colors: "#2D3436",
          },
          markers: {
            radius: 12,
          },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        tooltip: {
          x: {
            show: false,
          },
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #ff3d00 #ff3d00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #fff #fff transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
