<template>
  <b-row>
    <b-col class="col-lg-6 col-12 mb-32 mt-32">
      <h1 class="mb-0">Undisputed Noobs Treasury</h1>
      <h5 class="mb-0 text-black-80 hp-text-color-dark-30">
        At Undisputed Noobs we want to provide as much transparency as possible
        to our members. That's why we have set up the Treasury. Here you will be
        able to see everything we have on all the games supported by the
        treasury.
      </h5>
    </b-col>
    <b-col class="col-lg-6 col-12 mb-32">
      <img
        src="https://undisputednoobs.com/wp-content/uploads/2022/01/banner-e1643328444738.jpg"
        alt="Genopets"
        class="img-fluid rounded shadow w-50 float-right mobile"
      />
    </b-col>
    <b-col cols="12" class="mb-32">
      <b-navbar toggleable type="dark" variant="dark" class="rounded shadow">
        <b-navbar-brand>{{ navbar_brand }}</b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse">
          <template #default="{ expanded }">
            <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
            <b-icon v-else icon="chevron-bar-down"></b-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item
              v-for="(item, index) in navbar_items"
              :key="index"
              :disabled="!item.active"
              :active="item.index === navbar_index"
              @click="navbar_item_click(item.index) && updateURL()"
            >
              {{ item.text }}
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-col>
    <b-col cols="12" v-if="navbar_index === 0">
      <div class="text-center center">
        <img
          src="@/assets/img/pages/treasury/select_option.svg"
          alt="Error"
          style="max-height: 175px"
        />
        <h2>Welcome! Please select a game to continue.</h2>
        <p>
          We can totally understand that some people may be surprised or not
          understand some of the numbers they will see in our treasury, so we
          encourage you to join us on Discord to ask us any questions you may
          have and help you understand better.
        </p>
      </div>
    </b-col>
    <genopets v-if="navbar_index === 1" />
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BNavbarNav,
  BNav,
  BNavItem,
  BNavItemDropdown,
  BNavForm,
  BCollapse,
  BIcon,
} from "bootstrap-vue";

import Genopets from "./Genopets.vue";

export default {
  components: {
    BRow,
    BCol,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BNavbarNav,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BNavForm,
    BCollapse,
    BIcon,
    Genopets,
  },
  data() {
    return {
      navbar_index: 0,
      navbar_brand: "Select a game",
      navbar_items: [
        { index: 1, text: "Genopets", active: true, url: "genopets" },
        {
          index: 2,
          text: "Million on Mars (coming soon)",
          active: false,
          url: "milliononmars",
        },
      ],
    };
  },
  methods: {
    navbar_item_click(index) {
      this.navbar_index = index;
      this.navbar_brand = this.navbar_items[this.navbar_index - 1].text;
    },
  },
  mounted() {
    var selected_game = this.$route.query.game;
    if (selected_game) {
      this.navbar_items.forEach((item) => {
        if (item.url === selected_game) {
          this.navbar_item_click(item.index);
        }
      });
    }

    this.$watch("navbar_index", (navbar_index) => {
      this.$router.push({
        query: { game: this.navbar_items[navbar_index - 1].url },
      });
    });
  },
};
</script>

<style scoped lang="scss">
.mobile {
  @media (max-width: 768px) {
    display: none;
  }
}
</style>
