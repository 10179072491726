import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://api-unp.sacul.cloud/games/genopets/";

class GenopetsAdmin {
  async getHabitats() {
    var data = await axios
      .get(API_URL + "getHabitats", { headers: authHeader() })
      .then((response) => {
        return response.data.data;
      });

    return data;
  }

  async getTreasury() {
    var data = await axios
      .get(API_URL + "getTreasury", { headers: authHeader() })
      .then((response) => {
        return response.data;
      });

    return data;
  }
}

export default new GenopetsAdmin();
